import * as React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <article className="page page--404">
      <section className="container-md">
        <div className="content col-md-6 mx-auto">
          <h1>NOT FOUND</h1>
          <p>The address you were looking for could not be found.</p>
        </div>
      </section>
    </article>
  </Layout>
);

export default NotFoundPage;
